// REFERENCED FROM HERE https://cantina.co/building-a-better-button-in-css/
.button {
  background-color: transparent;
  border: 0;
  border-radius: $border-radius-full;
  color: $white-500;
  font-family: $fw-500;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: all 0.1s ease-in-out;

  cursor: pointer;

  &[disabled] {
    @include disabled-button-styles();
  }

  &:focus {
    outline-color: inherit;
  }

  // COLORS
  // `#{&}` interpolates the ampersand selector, letting you target multiple classes on the same element;
  &--primary#{&}--solid {
    background-color: $blue-500;

    &:hover,
    &:focus,
    &:active {
      background-color: $blue-300;
    }

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--secondary#{&}--solid {
    color: $black-500;
    background-color: $white-500;
    border: 1px solid $black-100;

    &:hover,
    &:focus,
    &:active {
      background-color: $black-050;
    }

    &[disabled] {
      color: $black-100;
      background-color: transparent;
      border-color: $black-050;
    }
  }

  &--tertiary#{&}--solid {
    color: $black-500;
    background-color: $black-025;

    &:hover,
    &:focus,
    &:active {
      background-color: $black-050;
    }

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--yellow#{&}--solid {
    color: $black-500;
    background-color: $yellow-500;

    &:hover,
    &:focus,
    &:active {
      background-color: $yellow-300;
    }

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--yellow-light#{&}--solid {
    color: $black-500;
    background-color: $yellow-050;

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--pink#{&}--solid {
    color: $white-500;
    background-color: $pink-500;

    &:hover,
    &:focus,
    &:active {
      background-color: $pink-300;
    }

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--red#{&}--solid {
    color: $white-500;
    background-color: $red-500;

    &:hover,
    &:focus,
    &:active {
      background-color: $red-300;
    }

    &[disabled] {
      @include disabled-button-styles();
    }
  }

  &--white#{&}--solid {
    color: $black-500;
    background-color: $white-500;

    &:hover,
    &:focus,
    &:active {
      color: $black-500;
      background-color: rgba($white-500, 0.8);
    }

    &[disabled] {
      color: rgba($white-500, 0.3);
      background-color: rgba($white-500, 0.2);
    }
  }

  // SIZES
  &--sm {
    padding: $spacing-8 $spacing-32;
    font-size: $text-14;
    min-height: 4rem;
  }

  &--sm#{&}--icon {
    font-size: $text-18;
    height: 4rem;
    width: 4rem;
    padding: 1.1rem;
  }

  &--white#{&}--outlined {
    color: $white-500;
    background-color: transparent;
    border: 1px solid $white-500;

    &:hover,
    &:focus,
    &:active {
      color: $white-500;
      background-color: rgba($white-500, 0.05);
    }

    &[disabled] {
      color: rgba($white-500, 0.3);
      background-color: transparent;
      border-color: rgba($white-500, 0.3);
    }
  }

  &--sm#{&}--icon-left {
    [class^="material-"] {
      font-size: $text-18;
      margin-right: $spacing-12;
    }
  }

  &--sm#{&}--icon-right {
    [class^="material-"] {
      font-size: $text-18;
      margin-left: $spacing-12;
    }
  }

  &--md {
    padding: $spacing-12 $spacing-40;
    font-size: $text-14;
    min-height: 4.8rem;
  }

  &--md#{&}--icon {
    font-size: $text-18;
    height: 4.8rem;
    width: 4.8rem;
    padding: 1.5rem;
  }

  &--md#{&}--icon-left {
    [class^="material-"] {
      font-size: $text-18;
      margin-right: $spacing-12;
    }
  }

  &--md#{&}--icon-right {
    [class^="material-"] {
      font-size: $text-18;
      margin-left: $spacing-12;
    }
  }

  &--lg {
    padding: $spacing-16 $spacing-48;
    font-size: $text-16;
    min-height: 5.6rem;
  }

  &--lg#{&}--icon {
    height: 5.6rem;
    width: 5.6rem;
    font-size: $text-24;
    padding: $spacing-16;
    border-radius: $border-radius-full;
  }

  &--lg#{&}--icon-left {
    [class^="material-"] {
      font-size: $text-24;
      margin-right: $spacing-12;
    }
  }

  &--lg#{&}--icon-right {
    [class^="material-"] {
      font-size: $text-24;
      margin-left: $spacing-12;
    }
  }
}

.text-button {
  background-color: transparent;
  border: 0;
  color: $white-500;
  font-family: $fw-500;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: all 0.1s ease-in-out;

  cursor: pointer;

  &[disabled] {
    @include disabled-text-button-styles();
  }

  &:focus {
    outline-color: inherit;
  }

  // COLORS
  &--primary#{&}--solid {
    color: $blue-500;

    &:hover,
    &:focus,
    &:active {
      color: $blue-300;
    }

    &[disabled] {
      @include disabled-text-button-styles();
    }
  }

  &--secondary#{&}--solid {
    color: $black-200;

    &:hover,
    &:focus,
    &:active {
      color: $black-300;
    }

    &[disabled] {
      @include disabled-text-button-styles();
    }
  }

  &--pink#{&}--solid {
    color: $pink-500;

    &:hover,
    &:focus,
    &:active {
      color: $pink-300;
    }

    &[disabled] {
      @include disabled-text-button-styles();
    }
  }

  // SIZES
  &--sm {
    font-size: $text-14;
  }

  &--sm#{&}--icon {
    font-size: $text-18;
  }

  &--sm#{&}--icon-left {
    [class^="material-"] {
      font-size: $text-18;
      margin-right: $spacing-8;
    }
  }

  &--sm#{&}--icon-right {
    [class^="material-"] {
      font-size: $text-18;
      margin-left: $spacing-8;
    }
  }

  &--md {
    font-size: $text-16;
  }

  &--md#{&}--icon {
    font-size: $text-24;
  }

  &--md#{&}--icon-left {
    [class^="material-"] {
      font-size: $text-24;
      margin-right: $spacing-8;
    }
  }

  &--md#{&}--icon-right {
    [class^="material-"] {
      font-size: $text-24;
      margin-left: $spacing-8;
    }
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    width: 100%;
    height: 48px;
    border-radius: $border-radius-full;
    -webkit-appearance: -apple-pay-button;
    text-indent: -9999px;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 100%;
    height: 48px;
    border: 0;
    border-radius: $border-radius-full;
    font-size: $text-18;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    font-weight: 500;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
    color: white;

    .logo {
      background-image: url("../../assets/logos/apple-pay-logo.png");
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .container-that-offers-apple-pay {
    display: none;
  }
}

.google-pay-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 100%;
  height: 48px;
  border: 0;
  font-size: $text-16;
}
.google-pay-button-black {
  background-color: black;
  color: white;

  .logo {
    height: 24px;
  }
}

.google-login-button {
  &--lg {
    img {
      margin-right: $spacing-12;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
