// BACKGROUND COLORS
@each $bg-color-name, $shades in $colors {
  @each $shade, $value in $shades {
    .bg-#{"" + $bg-color-name}-#{$shade} {
      background-color: $value;
    }
    .hover\:bg-#{"" + $bg-color-name}-#{$shade} {
      &:hover {
        background-color: $value;
      }
    }
  }
}

// (WHITE LABEL DS) BACKGROUND COLORS
@each $bg-color-name, $shades in $wl-colors {
  @each $shade, $value in $shades {
    .bg-#{"" + $bg-color-name}-#{$shade} {
      background-color: $value;
    }
    .hover\:bg-#{"" + $bg-color-name}-#{$shade} {
      &:hover {
        background-color: $value;
      }
    }
  }
}

// TEXT COLORS
@each $color-name, $shades in $colors {
  @each $shade, $value in $shades {
    .text-#{"" + $color-name}-#{$shade} {
      color: $value;
    }
    .hover\:text-#{"" + $color-name} {
      &:hover {
        color: $value;
      }
    }
  }
}

// (WHITE LABEL DS) TEXT COLORS
@each $color-name, $shades in $wl-colors {
  @each $shade, $value in $shades {
    .text-#{"" + $color-name}-#{$shade} {
      color: $value;
    }
    .hover\:text-#{"" + $color-name} {
      &:hover {
        color: $value;
      }
    }
  }
}

// WIDTHS
@each $name, $size in $width {
  .w-#{$name} {
    width: $size;
  }
  .min-w-#{$name} {
    min-width: $size;
  }
}
@each $name, $size in $spacings {
  .w-#{$name} {
    width: $size;
  }
  .min-w-#{$name} {
    min-width: $size;
  }
}

// HEIGHTS
@each $name, $size in $height {
  .h-#{$name} {
    height: $size;
  }
  .min-h-#{$name} {
    min-height: $size;
  }
}
@each $name, $size in $spacings {
  .h-#{$name} {
    height: $size;
  }
  .min-h-#{$name} {
    min-height: $size;
  }
}

// PADDINGS AND MARGINS
@each $name, $size in $spacings {
  .p-#{$name} {
    padding: $size;
  }
  .pt-#{$name} {
    padding-top: $size;
  }
  .pr-#{$name} {
    padding-right: $size;
  }
  .pb-#{$name} {
    padding-bottom: $size;
  }
  .pl-#{$name} {
    padding-left: $size;
  }
  .py-#{$name} {
    padding-top: $size;
    padding-bottom: $size;
  }
  .px-#{$name} {
    padding-right: $size;
    padding-left: $size;
  }
  .m-#{$name} {
    margin: $size;
  }
  .mt-#{$name} {
    margin-top: $size;
  }
  .mr-#{$name} {
    margin-right: $size;
  }
  .mb-#{$name} {
    margin-bottom: $size;
  }
  .ml-#{$name} {
    margin-left: $size;
  }
  .my-#{$name} {
    margin-top: $size;
    margin-bottom: $size;
  }
  .mx-#{$name} {
    margin-right: $size;
    margin-left: $size;
  }
  .top-#{$name} {
    top: $size;
  }
  .right-#{$name} {
    right: $size;
  }
  .bottom-#{$name} {
    bottom: $size;
  }
  .left-#{$name} {
    left: $size;
  }
}

// NEGATIVE MARGINS
@each $name, $size in $spacings-n {
  .-m-#{$name} {
    margin: $size;
  }
  .-mt-#{$name} {
    margin-top: $size;
  }
  .-mr-#{$name} {
    margin-right: $size;
  }
  .-mb-#{$name} {
    margin-bottom: $size;
  }
  .-ml-#{$name} {
    margin-left: $size;
  }
  .-my-#{$name} {
    margin-top: $size;
    margin-bottom: $size;
  }
  .-mx-#{$name} {
    margin-right: $size;
    margin-left: $size;
  }
  .-top-#{$name} {
    top: $size;
  }
  .-right-#{$name} {
    right: $size;
  }
  .-bottom-#{$name} {
    bottom: $size;
  }
  .-left-#{$name} {
    left: $size;
  }
}

// SPACE BETWEEN CHILDREN
@each $name, $size in $spacings {
  .space-x-#{$name} {
    & > *:not(:first-child) {
      margin-left: $size;
    }
  }
  .space-y-#{$name} {
    & > *:not(:first-child) {
      margin-top: $size;
    }
  }
}

// POSITIONS
@each $position in $positions {
  .#{$position} {
    position: $position;
  }
}

// Z-INDEXES
@each $z-index in $z-indexes {
  .z-#{$z-index} {
    z-index: $z-index;
  }
}

// DISPLAY TYPES
@each $display in $displays {
  .#{$display} {
    display: $display;
  }
}

// OPACITY
@each $name, $value in $opacity {
  .opacity-#{$name} {
    opacity: $value;
  }
  .hover\:opacity-#{$name} {
    &:hover {
      opacity: $value;
    }
  }
}

// BOX SHADOWS
@each $name, $value in $box-shadows {
  .shadow-#{$name} {
    box-shadow: #{$value};
  }
  .text-shadow-#{$name} {
    text-shadow: #{$value};
  }
  .shadow-inset-#{$name} {
    box-shadow: inset #{$value};
  }
  .hover\:shadow-#{$name} {
    &:hover {
      box-shadow: #{$value};
    }
  }
}

// BORDER COLORS
@each $border-color-name, $shades in $colors {
  @each $shade, $value in $shades {
    .border-color-#{"" + $border-color-name}-#{$shade} {
      border-color: $value !important;
    }
    .hover\:border-color-#{"" + $border-color-name}-#{$shade} {
      &:hover {
        border-color: $value !important;
      }
    }
  }
}

// (WHITE LABEL DS) BORDER COLORS
@each $border-color-name, $shades in $wl-border-colors {
  @each $shade, $value in $shades {
    .border-color-#{"" + $border-color-name}-#{$shade} {
      border-color: $value !important;
    }
    .hover\:border-color-#{"" + $border-color-name}-#{$shade} {
      &:hover {
        border-color: $value !important;
      }
    }
  }
}

// BORDER RADIUS
@each $name, $size in $rounded-borders {
  .#{$name} {
    border-radius: $size;
  }
  .hover\:#{$name} {
    &:hover {
      border-radius: $size;
    }
  }
}

// CURSOR STATES
@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: $cursor;
  }
  .hover\:cursor-#{$cursor} {
    &:hover {
      cursor: $cursor;
    }
  }
}

// USER SELECTS
@each $user-select in $user-selects {
  .select-#{$user-select} {
    user-select: $user-select;
  }
  .hover\:select-#{$user-select} {
    &:hover {
      user-select: $user-select;
    }
  }
}

// POINTER EVENTS
@each $pointer-event in $pointer-events {
  .pointer-events-#{$pointer-event} {
    pointer-events: $pointer-event;
  }
}

// OVERFLOWS
@each $overflow in $overflows {
  .overflow-#{$overflow} {
    overflow: $overflow;
  }
  .overflow-x-#{$overflow} {
    overflow-x: $overflow;
  }
  .overflow-y-#{$overflow} {
    overflow-y: $overflow;
  }
}

// MAMO ANGLE
.mamo-angle {
  clip-path: polygon(0 2.4rem, 100% 0, 100% 100%, 0% 100%);
}

// RESPONSIVE: BACKGROUND COLORS
@each $bg-color-name, $shades in $colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:bg-#{"" + $bg-color-name}-#{$shade} {
          background-color: $value;
        }
        .#{$bp-name}\:hover\:bg-#{"" + $bg-color-name}-#{$shade} {
          &:hover {
            background-color: $value;
          }
        }
      }
    }
  }
}

// (WHITE LABEL DS) RESPONSIVE: BACKGROUND COLORS
@each $bg-color-name, $shades in $wl-colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:bg-#{"" + $bg-color-name}-#{$shade} {
          background-color: $value;
        }
        .#{$bp-name}\:hover\:bg-#{"" + $bg-color-name}-#{$shade} {
          &:hover {
            background-color: $value;
          }
        }
      }
    }
  }
}

// RESPONSIVE: TEXT COLORS
@each $color-name, $shades in $colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:text-#{"" + $color-name}-#{$shade} {
          color: $value;
        }

        .#{$bp-name}\:hover\:text-#{"" + $color-name}-#{$shade} {
          &:hover {
            color: $value;
          }
        }
      }
    }
  }
}

// (WHITE LABEL DS) RESPONSIVE: TEXT COLORS
@each $color-name, $shades in $wl-colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:text-#{"" + $color-name}-#{$shade} {
          color: $value;
        }

        .#{$bp-name}\:hover\:text-#{"" + $color-name}-#{$shade} {
          &:hover {
            color: $value;
          }
        }
      }
    }
  }
}

// RESPONSIVE: WIDTHS
@each $name, $size in $width {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:w-#{$name} {
        width: $size;
      }
      .#{$bp-name}\:min-w-#{$name} {
        min-width: $size;
      }
    }
  }
}

// RESPONSIVE: HEIGHTS
@each $name, $size in $height {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:h-#{$name} {
        height: $size;
      }
      .#{$bp-name}\:min-h-#{$name} {
        min-height: $size;
      }
    }
  }
}

// RESPONSIVE: PADDINGS AND MARGINS
@each $name, $size in $spacings {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:p-#{$name} {
        padding: $size;
      }
      .#{$bp-name}\:pt-#{$name} {
        padding-top: $size;
      }
      .#{$bp-name}\:pr-#{$name} {
        padding-right: $size;
      }
      .#{$bp-name}\:pb-#{$name} {
        padding-bottom: $size;
      }
      .#{$bp-name}\:pl-#{$name} {
        padding-left: $size;
      }
      .#{$bp-name}\:py-#{$name} {
        padding-top: $size;
        padding-bottom: $size;
      }
      .#{$bp-name}\:px-#{$name} {
        padding-right: $size;
        padding-left: $size;
      }
      .#{$bp-name}\:m-#{$name} {
        margin: $size;
      }
      .#{$bp-name}\:mt-#{$name} {
        margin-top: $size;
      }
      .#{$bp-name}\:mr-#{$name} {
        margin-right: $size;
      }
      .#{$bp-name}\:mb-#{$name} {
        margin-bottom: $size;
      }
      .#{$bp-name}\:ml-#{$name} {
        margin-left: $size;
      }
      .#{$bp-name}\:my-#{$name} {
        margin-top: $size;
        margin-bottom: $size;
      }
      .#{$bp-name}\:mx-#{$name} {
        margin-right: $size;
        margin-left: $size;
      }
      .#{$bp-name}\:top-#{$name} {
        top: $size;
      }
      .#{$bp-name}\:right-#{$name} {
        right: $size;
      }
      .#{$bp-name}\:bottom-#{$name} {
        bottom: $size;
      }
      .#{$bp-name}\:left-#{$name} {
        left: $size;
      }
    }
  }
}

// RESPONSIVE: NEGATIVE MARGINS
@each $name, $size in $spacings-n {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:-m-#{$name} {
        margin: $size;
      }
      .#{$bp-name}\:-mt-#{$name} {
        margin-top: $size;
      }
      .#{$bp-name}\:-mr-#{$name} {
        margin-right: $size;
      }
      .#{$bp-name}\:-mb-#{$name} {
        margin-bottom: $size;
      }
      .#{$bp-name}\:-ml-#{$name} {
        margin-left: $size;
      }
      .#{$bp-name}\:-my-#{$name} {
        margin-top: $size;
        margin-bottom: $size;
      }
      .#{$bp-name}\:-mx-#{$name} {
        margin-right: $size;
        margin-left: $size;
      }
      .#{$bp-name}\:-top-#{$name} {
        top: $size;
      }
      .#{$bp-name}\:-right-#{$name} {
        right: $size;
      }
      .#{$bp-name}\:-bottom-#{$name} {
        bottom: $size;
      }
      .#{$bp-name}\:-left-#{$name} {
        left: $size;
      }
    }
  }
}

// RESPONSIVE: SPACE BETWEEN CHILDREN
@each $name, $size in $spacings {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:space-x-#{$name} {
        & > *:not(:first-child) {
          margin-left: $size;
        }
      }
      .#{$bp-name}\:space-y-#{$name} {
        & > *:not(:first-child) {
          margin-top: $size;
        }
      }
    }
  }
}

// RESPONSIVE: POSITIONS
@each $position in $positions {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:#{$position} {
        position: $position;
      }
    }
  }
}

// RESPONSIVE: Z-INDEXES
@each $z-index in $z-indexes {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:z-#{$z-index} {
        z-index: $z-index;
      }
    }
  }
}

// RESPONSIVE: DISPLAY TYPES
@each $display in $displays {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:#{$display} {
        display: $display;
      }
    }
  }
}

// RESPONSIVE: OPACITY
@each $name, $value in $opacity {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:opacity-#{$name} {
        opacity: $value;
      }
    }
  }
}

// RESPONSIVE: BORDER COLORS
@each $border-color-name, $shades in $colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:border-color-#{"" + $border-color-name}-#{$shade} {
          border-color: $value;
        }
        .#{$bp-name}\:hover\:border-color-#{"" + $border-color-name}-#{$shade} {
          &:hover {
            border-color: $value;
          }
        }
      }
    }
  }
}

// (WHITE LABEL DS) RESPONSIVE: BORDER COLORS
@each $border-color-name, $shades in $wl-border-colors {
  @each $shade, $value in $shades {
    @each $bp-name, $bp-size in $breakpoints {
      @include respond($bp-name) {
        .#{$bp-name}\:border-color-#{"" + $border-color-name}-#{$shade} {
          border-color: $value;
        }
        .#{$bp-name}\:hover\:border-color-#{"" + $border-color-name}-#{$shade} {
          &:hover {
            border-color: $value;
          }
        }
      }
    }
  }
}

// RESPONSIVE: BORDER RADIUS
@each $name, $size in $rounded-borders {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:#{$name} {
        border-radius: $size;
      }
      .#{$bp-name}\:hover\:bg-#{$name} {
        &:hover {
          border-radius: $size;
        }
      }
    }
  }
}

// RESPONSIVE: OVERFLOWS
@each $overflow in $overflows {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:overflow-#{$overflow} {
        overflow: $overflow;
      }
      .#{$bp-name}\:overflow-x-#{$overflow} {
        overflow-x: $overflow;
      }
      .#{$bp-name}\:overflow-y-#{$overflow} {
        overflow-y: $overflow;
      }
    }    
  }
}
