// MEDIA QUERY MANAGER MIXIN
@mixin respond($breakpoint) {
  @if $breakpoint == "xs" {
    @media only screen and (min-width: 20em) {
      @content;
    } //320px
  }
  @if $breakpoint == "sm" {
    @media only screen and (min-width: 23.4375em) {
      @content;
    } //375px
  }
  @if $breakpoint == "md" {
    @media only screen and (min-width: 48em) {
      @content;
    } //768px
  }
  @if $breakpoint == "lg" {
    @media only screen and (min-width: 64em) {
      @content;
    } //1024px
  }
  @if $breakpoint == "tablet" {
    @media only screen and (min-width: 64em) and (orientation: landscape) {
      @content;
    }
  } // 1024px + orientation landscape
  @if $breakpoint == "xl" {
    @media only screen and (min-width: 80em) and (hover: hover) {
      @content;
    } //1280px
  }
}
// ======================================================================================

// CLAMP FONT MIXIN
@mixin responsive-font($min, $preferred, $max) {
  font-size: clamp($min, $preferred, $max);
}
// ======================================================================================

// GLOBAL GRID MIXIN
@mixin global-grid($max-width: 1200px) {
  margin: 0 $spacing-20;
  
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: $spacing-16;
  
  @include respond(md) {
    margin: 0 $spacing-32;
    grid-template-columns: repeat(9, 1fr);
  }
  
  @include respond(lg) {
    max-width: $max-width;
    margin: 0 auto;
    
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $spacing-24;
  }
}

// DISABLED BUTTON MIXIN
@mixin disabled-button-styles {
  cursor: not-allowed;
  color: $black-100 !important;
  background-color: $black-025 !important;
}
@mixin disabled-text-button-styles {
  cursor: not-allowed;
  color: $black-050;
}
// ======================================================================================

// ICON BUTTON MIXIN
@mixin icon-button($position-one, $position-two) {
  font-size: $text-24;
  color: $black-500;
  background-color: transparent;
  padding: 0;
  border: none;
  border-radius: $border-radius-full;

  position: absolute;
  #{$position-one}: $spacing-20;
  #{$position-two}: $spacing-20;

  &:focus {
    outline-color: inherit;
  }

  @include respond(md) {
    font-size: $text-18;
    background-color: $white-500;
    height: 4rem;
    width: 4rem;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include respond(lg) {
    #{$position-one}: $spacing-24;
    #{$position-two}: $spacing-24;
  }
}
// ======================================================================================

// TOOLTIP MIXIN
@mixin tooltip(
  $class-context,
  $tooltip-width,
  $tooltip-position-top-xs,
  $tooltip-position-left-xs,
  $tooltip-position-top-md,
  $tooltip-position-left-md,
  $tooltip-position-top-lg,
  $tooltip-position-left-lg,
  $triangle-position-top-xs,
  $triangle-position-left-xs,
  $triangle-position-top-md,
  $triangle-position-left-md,
  $triangle-position-top-lg,
  $triangle-position-left-lg
) {
  .#{$class-context}__tooltip-container {
    position: relative;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;

    &__info {
      color: $black-200;
      font-size: $text-18;
      margin-left: $spacing-8;

      transition: all 0.2s ease-in-out;

      &:focus {
        color: $black-500;
        outline-color: inherit;
      }

      &:hover {
        cursor: pointer;
        color: $black-500;
      }
    }

    &__info:hover + &__tooltip {
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
      transition: 0.2s ease-in-out;
    }

    &__tooltip {
      visibility: hidden;
      z-index: 1;
      opacity: 0.4;

      min-width: $tooltip-width;
      padding: $spacing-8 $spacing-16;

      background: $black-400;
      color: $white-500;

      content: attr(data-tooltip);
      position: absolute;
      top: $tooltip-position-top-xs;
      left: $tooltip-position-left-xs;

      border-radius: $border-radius-md;
      font-size: $text-14;

      transform: translateY(10px);
      transition: all 0.2s ease-in-out;

      @include respond(md) {
        top: $tooltip-position-top-md;
        left: $tooltip-position-left-md;
      }

      @include respond(lg) {
        top: $tooltip-position-top-lg;
        left: $tooltip-position-left-lg;
      }

      // the triangle part
      &::before {
        content: " ";
        width: 0;
        height: 0;

        border: 12px solid transparent;
        border-top: 0;
        border-bottom: 12px solid $black-400;

        position: absolute;
        top: $triangle-position-top-xs;
        left: $triangle-position-left-xs;

        @include respond(md) {
          top: $triangle-position-top-md;
          left: $triangle-position-left-md;
        }

        @include respond(lg) {
          top: $triangle-position-top-lg;
          left: $triangle-position-left-lg;
        }
      }
    }
  }
}
// ======================================================================================

// STATUS TOOLTIP MIXIN
@mixin status-tooltip(
  $class-context,
  $tooltip-width,
  $tooltip-position-top-xs,
  $tooltip-position-left-xs,
  $tooltip-position-top-md,
  $tooltip-position-left-md,
  $tooltip-position-top-lg,
  $tooltip-position-left-lg,
  $triangle-position-top-xs,
  $triangle-position-left-xs,
  $triangle-position-top-md,
  $triangle-position-left-md,
  $triangle-position-top-lg,
  $triangle-position-left-lg
) {
  .#{$class-context}__tooltip {
    position: relative;

    &::after {
      visibility: hidden;
      z-index: 1;
      opacity: 0.4;

      min-width: $tooltip-width;
      padding: $spacing-8 $spacing-16;

      background: $black-400;
      color: $white-500;
      font-family: $fw-400;

      content: attr(data-tooltip);
      position: absolute;
      top: $tooltip-position-top-xs;
      left: $tooltip-position-left-xs;

      border-radius: $border-radius-md;
      font-size: $text-14;

      transform: translateY(10px);
      transition: all 0.2s ease-in-out;

      @include respond(md) {
        top: $tooltip-position-top-md;
        left: $tooltip-position-left-md;
      }

      @include respond(lg) {
        top: $tooltip-position-top-lg;
        left: $tooltip-position-left-lg;
      }
    }

    &:hover::after {
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
      transition: all 0.1s ease-in-out;
    }

    &--top {
      &::before {
        content: " ";
        width: 0;
        height: 0;

        border: 12px solid transparent;
        border-top: 0;
        border-bottom: 12px solid $black-400;

        position: absolute;
        top: $triangle-position-top-xs;
        left: $triangle-position-left-xs;

        transform: translateY(10px);
        transition: all 0.2s ease-in-out;

        opacity: 0;
        visibility: hidden;

        @include respond(md) {
          top: $triangle-position-top-md;
          left: $triangle-position-left-md;
        }

        @include respond(lg) {
          top: $triangle-position-top-lg;
          left: $triangle-position-left-lg;
        }
      }

      &:hover::before {
        transform: translateY(0px);

        opacity: 1;
        visibility: visible;
      }
    }
  }
}
// ======================================================================================

// MORE DROPDOWN MENU MIXIN
@mixin more-dropdown-menu(
  $class-context,
  $menu-width,
  $font-weight,
  $menu-position-top-xs,
  $menu-position-right-xs,
  $menu-position-top-md,
  $menu-position-right-md,
  $menu-position-top-lg,
  $menu-position-right-lg
) {
  .#{$class-context}-menu {
    width: $menu-width;
    padding: $spacing-8;
    font-family: $font-weight;
    background-color: $white-500;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-md;

    position: absolute;
    top: $menu-position-top-xs;
    right: $menu-position-right-xs;
    z-index: 99;

    @include respond(md) {
      top: $menu-position-top-md;
      right: $menu-position-right-md;
    }

    @include respond(lg) {
      top: $menu-position-top-lg;
      right: $menu-position-right-lg;
    }

    &__link {
      font-size: $text-14;
      cursor: pointer;

      &:focus-visible {
        outline: none;
        background-color: $black-025;
        border-radius: $border-radius-md;
      }
    }

    &__item {
      padding: $spacing-12;
      display: flex;
      align-items: flex-start;
      border-radius: $border-radius-md;

      @include respond(md) {
        padding: $spacing-12 $spacing-16;
      }

      &:hover,
      &--active {
        background-color: $black-025;
      }
    }

    &__icon {
      margin-top: 2px;
      margin-right: $spacing-12;
      font-size: $text-18;
      color: $black-200;

      &--upgrade {
        color: $yellow-500;
      }
    }

    &__helper-text {
      display: inline-block;
      margin-top: $spacing-8;
      color: $black-300;
      font-size: $text-12;
    }
  }
}
// ======================================================================================

// CARD MIXIN
@mixin card {
  background-color: $white-500;
  padding: $spacing-24;
  border-radius: $border-radius-lg;

  @include respond(lg) {
    padding: $spacing-32;
  }
}

@mixin card--icon($icon-bg-color, $icon-color) {
  @include card();

  border: 2px solid $black-050;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond(md) {
    align-items: center;
  }

  @include respond(lg) {
    padding: $spacing-24;
  }

  &__info {
    display: flex;
    align-items: flex-start;

    @include respond(md) {
      align-items: center;
    }
  }

  &__initials,
  &__icon {
    background-color: $icon-bg-color;
    color: $icon-color;
    min-height: 4.8rem;
    min-width: 4.8rem;
    border-radius: $border-radius-full;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(md) {
      min-height: 5.6rem;
      min-width: 5.6rem;
    }
  }

  &__initials {
    font-family: $fw-500;
    font-size: $text-16;
  }

  &__icon {
    font-size: $text-24;
  }

  &__content {
    margin-left: $spacing-16;

    &__name {
      font-size: $text-16;
      font-family: $fw-500;
    }

    &__details {
      margin-top: $spacing-8;

      @include respond(md) {
        margin-top: 4px;
        display: flex;
        align-items: center;

        & > * {
          margin-right: $spacing-8;
        }
      }

      p {
        color: $black-300;
        font-size: $text-14;
      }

      span {
        display: none;
        color: $black-200;

        @include respond(md) {
          display: inline-block;
          font-size: 10px;
        }
      }
    }
  }

  &__controls {
    font-size: $text-18;
    color: $black-200;

    & > *:not(:last-child) {
      margin-right: $spacing-12;
    }
  }

  &__button {
    width: 100%;
    margin-top: $spacing-16;

    @include respond(md) {
      width: auto;
      margin-top: 0;
    }

    & > * {
      width: inherit;
    }
  }
}

@mixin card-chin($chin-bg-color, $chin-icon-color) {
  @include card();

  .card__chin {
    margin: $spacing-24 $spacing-n-24 $spacing-n-24 $spacing-n-24;

    padding: $spacing-16 $spacing-24;
    background-color: $chin-bg-color;
    font-size: $text-16;

    border-bottom-left-radius: $spacing-16 - 0.2rem;
    border-bottom-right-radius: $spacing-16 - 0.2rem;

    display: flex;
    align-items: flex-start;

    @include respond(md) {
      align-items: center;
    }

    @include respond(lg) {
      margin: $spacing-n-32;
    }

    i {
      color: $chin-icon-color;
      margin-top: $spacing-4;
      margin-right: $spacing-8;

      @include respond(md) {
        margin-top: unset;
      }
    }
  }
}

// ======================================================================================

// OR MIXIN
@mixin or {
  margin-top: $spacing-24;
  padding: 1rem 0;

  font-size: $text-14;
  color: $black-300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 3;

  &::before,
  &::after {
    content: "";
    background-color: $black-050;
    height: 1px;
    flex-grow: 1;
  }

  &::before {
    margin-right: $spacing-24;
  }

  &::after {
    margin-left: $spacing-24;
  }
}

// ======================================================================================

// LIST MIXIN
@mixin list--icon(
  $li-spacing-xs,
  $li-spacing-md,
  $li-spacing-lg,
  $li-font-size,
  $icon-font,
  $icon-color,
  $icon-font-size,
  $icon-margin-right
) {
  & > *:not(:first-child) {
    margin-top: $li-spacing-xs;

    @include respond(md) {
      margin-top: $li-spacing-md;
    }

    @include respond(lg) {
      margin-top: $li-spacing-lg;
    }
  }

  &__list-item {
    display: flex;
    font-size: $li-font-size;

    &::before {
      content: "#{$icon-font}";
      font-family: "Material Icons Round";
      text-rendering: optimizeLegibility;
      font-feature-settings: "liga" 1;
      color: $icon-color;
      font-size: $icon-font-size;
      margin-right: $icon-margin-right;
    }
  }
}

// ======================================================================================
