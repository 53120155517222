
.field-message {
  margin-top: $spacing-12;
  padding: $spacing-8;
  border-radius: $border-radius-sm;
  color: $black-500;

  display: flex;
  align-items: flex-start;

  &__icon {
    font-size: $text-18;
  }

  &__info {
    margin-left: $spacing-4;
    font-size: $text-12;
  }

  &--inactive {
    color: $black-300;
    background-color: $black-025;

    span {
      color: $black-100;
    }
  }

  &--success {
    background-color: $green-050;

    span {
      color: $green-300;
    }
  }

  &--alert {
    background-color: $yellow-050;

    span {
      color: $yellow-500;
    }
  }

  &--error {
    color: $red-500;
    background-color: $red-050;
  }

  &--error-dark {
    color: $white-500;
    background-color: $red-400;
  }
}
