// BREAKPOINTS
$breakpoints: (
  "xs": "320px",
  "sm": "375px",
  "md": "768px",
  "lg": "1024px",
  "tablet": "1024px",
  "xl": "1440px",
);
// TEXT SIZES
$text-sizes: (
  "text-10": $text-10,
  "text-12": $text-12,
  "text-14": $text-14,
  "text-16": $text-16,
  "text-18": $text-18,
  "text-24": $text-24,
  "text-28": $text-28,
  "text-32": $text-32,
  "text-40": $text-40,
  "text-48": $text-48,
  "text-56": $text-56,
  "text-64": $text-64,
  "text-72": $text-72,
  "text-80": $text-80,
);

// SPACINGS - POSITIVE
$spacings: (
  "0": $spacing-0,
  "4": $spacing-4,
  "8": $spacing-8,
  "10": $spacing-10,
  "12": $spacing-12,
  "16": $spacing-16,
  "18": $spacing-18,
  "20": $spacing-20,
  "24": $spacing-24,
  "32": $spacing-32,
  "40": $spacing-40,
  "48": $spacing-48,
  "56": $spacing-56,
  "64": $spacing-64,
  "72": $spacing-72,
  "80": $spacing-80,
  "96": $spacing-96,
  "112": $spacing-112,
  "128": $spacing-128,
  "auto": auto,
);

// SPACINGS - NEGATIVE
$spacings-n: (
  "0": $spacing-n-0,
  "4": $spacing-n-4,
  "8": $spacing-n-8,
  "10": $spacing-n-10,
  "12": $spacing-n-12,
  "16": $spacing-n-16,
  "18": $spacing-n-18,
  "20": $spacing-n-20,
  "24": $spacing-n-24,
  "32": $spacing-n-32,
  "40": $spacing-n-40,
  "48": $spacing-n-48,
  "56": $spacing-n-56,
  "64": $spacing-n-64,
  "72": $spacing-n-72,
  "80": $spacing-n-80,
  "96": $spacing-n-96,
  "112": $spacing-n-112,
  "128": $spacing-n-128,
);

// FONT WEIGHTS
$font-weights: (
  "regular": $fw-400,
  "medium": $fw-500,
  "bold": $fw-700,
);

// COLORS
$colors: (
  blue: (
    500: $blue-500,
    400: $blue-400,
    300: $blue-300,
    200: $blue-200,
    100: $blue-100,
    50: $blue-050,
    25: $blue-025,
  ),
  pink: (
    500: $pink-500,
    400: $pink-400,
    300: $pink-300,
    200: $pink-200,
    100: $pink-100,
    50: $pink-050,
    25: $pink-025,
  ),
  yellow: (
    500: $yellow-500,
    400: $yellow-400,
    300: $yellow-300,
    200: $yellow-200,
    100: $yellow-100,
    50: $yellow-050,
    25: $yellow-025,
  ),
  red: (
    500: $red-500,
    400: $red-400,
    300: $red-300,
    200: $red-200,
    100: $red-100,
    50: $red-050,
    25: $red-025,
  ),
  green: (
    500: $green-500,
    400: $green-400,
    300: $green-300,
    200: $green-200,
    100: $green-100,
    50: $green-050,
    25: $green-025,
  ),
  black: (
    500: $black-500,
    400: $black-400,
    300: $black-300,
    200: $black-200,
    100: $black-100,
    50: $black-050,
    25: $black-025,
  ),
  white: (
    500: $white-500,
  ),
);

// (WHITE LABEL DS) COLORS
$wl-colors: (
  wl-white: (
    500: $wl-white-500,
    400: $wl-white-400,
    300: $wl-white-300,
    200: $wl-white-200,
    100: $wl-white-100,
    50: $wl-white-050,
    25: $wl-white-025,
  ),
  wl-black: (
    500: $wl-black-500,
    400: $wl-black-400,
    300: $wl-black-300,
    200: $wl-black-200,
    100: $wl-black-100,
    50: $wl-black-050,
    25: $wl-black-025,
  ),
);

// WIDTHS AND HEIGHTS
$width: (
  "auto": $auto,
  "fit-content": $fit-content,
  "max-content": $max-content,
  "10pct": 10%,
  "20pct": 20%,
  "30pct": 30%,
  "40pct": 40%,
  "50pct": 50%,
  "60pct": 60%,
  "70pct": 70%,
  "80pct": 80%,
  "90pct": 90%,
  "fifty": $fifty,
  "full": $full,
  "screen": $screen-100-w,
);
$height: (
  "auto": $auto,
  "full": $full,
  "10vh": $screen-10-h,
  "20vh": $screen-20-h,
  "30vh": $screen-30-h,
  "40vh": $screen-40-h,
  "50vh": $screen-50-h,
  "60vh": $screen-60-h,
  "70vh": $screen-70-h,
  "80vh": $screen-80-h,
  "90vh": $screen-90-h,
  "screen": $screen-100-h,
);

// (WHITE LABEL DS) BORDER COLORS
$wl-border-colors: (
  wl-white: (
    500: $wl-white-500,
    400: $wl-white-400,
    300: $wl-white-300,
    200: $wl-white-200,
    100: $wl-white-100,
    50: $wl-white-050,
    25: $wl-white-025,
  ),
  wl-black: (
    500: $wl-black-500,
    400: $wl-black-400,
    300: $wl-black-300,
    200: $wl-black-200,
    100: $wl-black-100,
    50: $wl-black-050,
    25: $wl-black-025,
  ),
);

// ROUNDED BORDERS
$rounded-borders: (
  "rounded-none": 0,
  "rounded-xs": $border-radius-xs,
  "rounded-sm": $border-radius-sm,
  "rounded-md": $border-radius-md,
  "rounded-lg": $border-radius-lg,
  "rounded-xl": $border-radius-xl,
  "rounded-full": $border-radius-full,
  "rounded-inherit": $border-radius-inherit,
);

// OPACITY
$opacity: (
  "5": $opacity-05,
  "10": $opacity-10,
  "20": $opacity-20,
  "30": $opacity-30,
  "40": $opacity-40,
  "50": $opacity-50,
  "60": $opacity-60,
  "70": $opacity-70,
  "80": $opacity-80,
  "90": $opacity-90,
  "100": $opacity-100,
);

// BOX SHADOWS
$box-shadows: (
  "sm": $box-shadow-sm,
  "md": $box-shadow-md,
);

// TEXT DECORATIONS
$text-decorations: (
  "no-underline": $td-none,
  "underline": $td-underline,
  "line-through": $td-line-through,
);
