.card {
  background-color: $white-500;
  padding: $spacing-24;
  border-radius: $border-radius-lg;

  @include respond(lg) {
    padding: $spacing-32;
  }

  &--icon-blue {
    @include card--icon($blue-025, $blue-500);
  }

  &--border {
    border: 2px solid $black-050;
  }
}

.subscription-card {
  padding: $spacing-24;
  border-radius: $border-radius-lg;
  border: 1px solid $blue-400;

  @include respond(md) {
    padding: $spacing-32;
  }
}
