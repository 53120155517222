.mpa-promo {
  padding: $spacing-24;
  border-radius: $border-radius-xl;

  background-color: $yellow-200;

  position: relative;
  overflow: hidden;

  grid-column: 1 / -1;

  @include respond(md) {
    padding: $spacing-32;
    grid-column: 3 / span 5;
  }

  @include respond(lg) {
    grid-column: 5 / span 4;
  }

  &__content {
    font-size: $text-16;
    font-family: $fw-500;

    p {
      width: 17.4rem;
    }
  }

  &__image {
    width: 10rem;
    position: absolute;
    right: 0;
    bottom: 0;

    @include respond(md) {
      width: 14rem;
    }
  }
}
