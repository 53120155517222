.loader-wrapper {
  background-color: $white-500;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__spinner {
    img,
    svg {
      height: 48px;
      width: 48px;
      padding: $spacing-8;
      animation: rotate 0.5s 0s linear infinite;
      animation-fill-mode: both;
    }
  }
}

.loader-ring-wrapper {
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .loader-ring {
    width: 6.4rem;
    height: 6.4rem;
    border: 6px solid $blue-050;
    border-radius: 50%;
    animation: loader-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $blue-500 $blue-500 $blue-050 $blue-050;
  }
}

