// TEXT SIZES
$text-10: 1rem;
$text-12: 1.2rem;
$text-14: 1.4rem;
$text-16: 1.6rem;
$text-18: 1.8rem;
$text-24: 2.4rem;
$text-28: 2.8rem;
$text-32: 3.2rem;
$text-40: 4rem;
$text-48: 4.8rem;
$text-56: 5.6rem;
$text-64: 6.4rem;
$text-72: 7.2rem;
$text-80: 8rem;

// SPACINGS - POSITIVE
$spacing-0: 0rem;
$spacing-4: 0.4rem;
$spacing-8: 0.8rem;
$spacing-10: 1rem;
$spacing-12: 1.2rem;
$spacing-16: 1.6rem;
$spacing-18: 1.8rem;
$spacing-20: 2rem;
$spacing-24: 2.4rem;
$spacing-32: 3.2rem;
$spacing-40: 4rem;
$spacing-48: 4.8rem;
$spacing-56: 5.6rem;
$spacing-64: 6.4rem;
$spacing-72: 7.2rem;
$spacing-80: 8rem;
$spacing-96: 9.6rem;
$spacing-112: 11.2rem;
$spacing-128: 12.8rem;
$spacing-auto: auto;

// SPACINGS - NEGATIVE
$spacing-n-0: -0rem;
$spacing-n-4: -0.4rem;
$spacing-n-8: -0.8rem;
$spacing-n-10: -1rem;
$spacing-n-12: -1.2rem;
$spacing-n-16: -1.6rem;
$spacing-n-18: -1.8rem;
$spacing-n-20: -2rem;
$spacing-n-24: -2.4rem;
$spacing-n-32: -3.2rem;
$spacing-n-40: -4rem;
$spacing-n-48: -4.8rem;
$spacing-n-56: -5.6rem;
$spacing-n-64: -6.4rem;
$spacing-n-72: -7.2rem;
$spacing-n-80: -8rem;
$spacing-n-96: -9.6rem;
$spacing-n-112: -11.2rem;
$spacing-n-128: -12.8rem;

// FONT-ALTERNATIVE
$font-alt: sans-serif;

// FONT WEIGHTS
$fw-400: "CircularXXWeb-Regular", $font-alt;
$fw-500: "CircularXXWeb-Medium", $font-alt;
$fw-700: "CircularXXWeb-Bold", $font-alt;

// COLORS
$blue-500: #3333ff;
$blue-400: #5252ff;
$blue-300: #7070ff;
$blue-200: #9999ff;
$blue-100: #c2c2ff;
$blue-050: #e7e7ff;
$blue-025: #f3f3ff;

$pink-500: #ff0066;
$pink-400: #ff267d;
$pink-300: #ff4d94;
$pink-200: #ff80b3;
$pink-100: #ffb3d1;
$pink-050: #ffe0ed;
$pink-025: #fff4f9;

$yellow-500: #ffd147;
$yellow-400: #ffd863;
$yellow-300: #ffdf7e;
$yellow-200: #ffe8a3;
$yellow-100: #fff1c8;
$yellow-050: #fff6dd;
$yellow-025: #fffbf1;

$red-500: #e3291d;
$red-400: #f64830;
$red-300: #ff6b57;
$red-200: #ff9f8a;
$red-100: #ffc8bc;
$red-050: #ffe8e3;
$red-025: #fff5f2;

$green-500: #1e8a5e;
$green-400: #2db37c;
$green-300: #46d39a;
$green-200: #7ee0b8;
$green-100: #bef0dc;
$green-050: #e3faf1;
$green-025: #f0fbf7;

$black-500: #080826;
$black-400: #26303e;
$black-300: #525a67;
$black-200: #9ba1b3;
$black-100: #c7cedc;
$black-050: #e5e9f0;
$black-025: #f3f5f9;

$white-500: #ffffff;

// (WHITE LABEL DS) COLORS
$wl-white-500: #ffffff;
$wl-white-400: rgba(255, 255, 255, 0.8);
$wl-white-300: rgba(255, 255, 255, 0.65);
$wl-white-200: rgba(255, 255, 255, 0.5);
$wl-white-100: rgba(255, 255, 255, 0.3);
$wl-white-050: rgba(255, 255, 255, 0.15);
$wl-white-025: rgba(255, 255, 255, 0.05);

$wl-black-500: #000000;
$wl-black-400: rgba(0, 0, 0, 0.8);
$wl-black-300: rgba(0, 0, 0, 0.65);
$wl-black-200: rgba(0, 0, 0, 0.5);
$wl-black-100: rgba(0, 0, 0, 0.3);
$wl-black-050: rgba(0, 0, 0, 0.15);
$wl-black-025: rgba(0, 0, 0, 0.05);

// POSITIONS
$positions: static, fixed, absolute, relative, sticky;

// Z-INDEXES
$z-indexes: 0, 10, 20, 30, 40, 50, auto;

// DISPLAYS
$displays: none, inline, inline-block, block, table, table-header-group, table-row, table-cell, inline-flex, flex, grid;

// WIDTHS AND HEIGHTS
$auto: auto;
$fit-content: fit-content;
$max-content: max-content;
$fifty: 45%;
$full: 100%;
$screen-100-w: 100vw;

$screen-10-h: 10vh;
$screen-20-h: 20vh;
$screen-30-h: 30vh;
$screen-40-h: 40vh;
$screen-50-h: 50vh;
$screen-60-h: 60vh;
$screen-70-h: 70vh;
$screen-80-h: 80vh;
$screen-90-h: 90vh;
$screen-100-h: 100vh;

// ROUNDED BORDERS
$border-radius-xs: 0.4rem;
$border-radius-sm: 0.8rem;
$border-radius-md: 1.2rem;
$border-radius-lg: 1.6rem;
$border-radius-xl: 2.4rem;
$border-radius-full: 99rem;
$border-radius-inherit: inherit;

// OPACITY
$opacity-05: 0.05;
$opacity-10: 0.1;
$opacity-20: 0.2;
$opacity-30: 0.3;
$opacity-40: 0.4;
$opacity-50: 0.5;
$opacity-60: 0.6;
$opacity-70: 0.7;
$opacity-80: 0.8;
$opacity-90: 0.9;
$opacity-100: 1;

// BOX SHADOWS
$box-shadow-sm: 0px 2px 4px rgba($black-500, $opacity-05);
$box-shadow-md: 0px 4px 20px rgba($black-500, $opacity-10);

// TEXT ALIGNMENTS
$text-alignments: left, right, center, justify;

// TEXT TRANSFORMS
$text-transforms: uppercase, lowercase, capitalize, none;

// TEXT DECORATIONS
$td-none: none;
$td-underline: underline;
$td-line-through: line-through;

// CURSOR STATES
$cursors: auto, default, pointer, wait, text, move, not-allowed;

// USER SELECTS
$user-selects: none, text, all, auto;

// POINTER EVENTS
$pointer-events: none, auto;

// OVERFLOWS
$overflows: auto, hidden, clip, visible, scroll;
