.monogram {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 4rem;
  height: 4rem;

  font-size: $text-14;
  font-family: $fw-500;
  color: $black-200;
  background-color: $black-025;

  border-radius: $border-radius-full;
  object-fit: cover;
}