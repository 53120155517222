// MAMO'S CIRCULAR FONT: https://assets.mamopay.com/stylesheet.css
@font-face {
  font-family: "CircularXXWeb-Regular";
  src: url("https://assets.mamopay.com/CircularXXWeb-Regular.woff") format("woff"),
    url("../../assets/fonts/CircularXXWeb-Regular.woff") format("woff"),
    url("https://assets.mamopay.com/CircularXXWeb-Regular.woff2") format("woff2"),
    url("../../assets/fonts/CircularXXWeb-Regular.woff2") format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "CircularXXWeb-Medium";
    src: url("https://assets.mamopay.com/CircularXXWeb-Medium.woff") format("woff"),
    url("../../assets/fonts/CircularXXWeb-Medium.woff") format("woff"),
    url("https://assets.mamopay.com/CircularXXWeb-Medium.woff2") format("woff2"),
    url("../../assets/fonts/CircularXXWeb-Medium.woff2") format("woff2");
    font-weight: 500;
  }
  @font-face {
    font-family: "CircularXXWeb-Bold";
    src: url("https://assets.mamopay.com/CircularXXWeb-Bold.woff") format("woff"),
    url("../../assets/fonts/CircularXXWeb-Bold.woff") format("woff"),
    url("https://assets.mamopay.com/CircularXXWeb-Bold.woff2") format("woff2"),
    url("../../assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
    font-weight: 700;
}
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/materialiconsround.woff2") format('woff2');
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%
}

body {
  font-family: $fw-400;
  color: $black-500;

  -webkit-font-smoothing: antialiased;
  -webkit-font-kerning: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fw-700;
  line-height: 1.25;
}

// fluid font style - enter clamp
h1,
.heading-01 {
  @include responsive-font($text-28, 5vw, $text-32);
}
h2,
.heading-02 {
  @include responsive-font($text-18, 5vw, $text-24);
}
h3,
.heading-03 {
  @include responsive-font($text-18, 5vw, $text-18);
}
h4,
.heading-04 {
  @include responsive-font($text-14, 5vw, $text-14);
}
h5,
.heading-05 {
  @include responsive-font($text-12, 5vw, $text-12);
}
h6,
.heading-06 {
  @include responsive-font($text-10, 5vw, $text-10);
}

// ::selection {
//   background-color: $blue-500;
//   color: $white-500;
// }

[class^="material-"],
[class*="material-"] {
  // external font stylesheet is already setting the display to inline-block
  // display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-size: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
input[type="text"],
textarea {
  padding-left: 0;
  -webkit-appearance: none;
  border-radius: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.unordered-list {
  list-style-type: disc;
  list-style-position: outside;
}

.rotate-n-135 {
  transform: rotate(-135deg);
}
