@use "sass:math";

$grid-columns: 12;
$grid-gaps: (
  "0": 0,
  "1": 8px,
  "2": 16px,
  "3": 24px,
);
$flex-direction: column, column-reverse, row, row-reverse, inherit;
$flex-wrap: wrap, wrap-reverse, nowrap;
$layout-values: baseline, stretch, flex-start, flex-end, center, space-between, space-around, space-evenly;

// base layout classes
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

  position: relative;
}
.row {
  display: flex;
  flex-flow: row wrap;
}

// grid gaps
@each $key, $val in $grid-gaps {
  .gap-#{$key} > * {
    padding: $val;
  }
  .gap-#{$key} {
    margin-left: -$val;
    margin-right: -$val;
  }
}

// grid gaps
@each $key, $val in $grid-gaps {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:gap-#{$key} > * {
        padding: $val;
      }
      .#{$bp-name}\:gap-#{$key} {
        margin-left: -$val;
        margin-right: -$val;
      }
    }
  }
}

// flex-direction classes
@each $val in $flex-direction {
  .flex-#{$val} {
    flex-direction: $val;
  }

  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:flex-#{$val} {
        flex-direction: $val;
      }
    }
  }
}

// flex-wrap classes
@each $val in $flex-wrap {
  .flex-#{$val} {
    flex-wrap: $val;
  }

  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:flex-#{$val} {
        flex-wrap: $val;
      }
    }
  }
}

// justify and align value
@each $val in $layout-values {
  .justify-#{$val} {
    justify-content: $val;
  }
  .align-#{$val} {
    align-items: $val;
  }
  .justify-self-#{$val} {
    justify-self: $val;
  }
  .align-self-#{$val} {
    align-self: $val;
  }
}

// RESPONSIVE: justify and align value
@each $val in $layout-values {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:justify-#{$val} {
        justify-content: $val;
      }
      .#{$bp-name}\:align-#{$val} {
        align-items: $val;
      }
      .#{$bp-name}\:justify-self-#{$val} {
        justify-self: $val;
      }
      .#{$bp-name}\:align-self-#{$val} {
        align-self: $val;
      }
    }
  }
}

// col classes
@include respond(xs) {
  @for $i from 1 through $grid-columns {
    .col-#{$i}-xs {
      box-sizing: border-box;
      flex-grow: 0;
      width: math.div($i * 100%, $grid-columns);
    }
  }
}
@include respond(sm) {
  @for $i from 1 through $grid-columns {
    .col-#{$i}-sm {
      box-sizing: border-box;
      flex-grow: 0;
      width: math.div($i * 100%, $grid-columns);
    }
  }
}
@include respond(md) {
  @for $i from 1 through $grid-columns {
    .col-#{$i}-md {
      box-sizing: border-box;
      flex-grow: 0;
      width: math.div($i * 100%, $grid-columns);
    }
  }
}
@include respond(lg) {
  @for $i from 1 through $grid-columns {
    .col-#{$i}-lg {
      box-sizing: border-box;
      flex-grow: 0;
      width: math.div($i * 100%, $grid-columns);
    }
  }
}
@include respond(xl) {
  @for $i from 1 through $grid-columns {
    .col-#{$i}-xl {
      box-sizing: border-box;
      flex-grow: 0;
      width: math.div($i * 100%, $grid-columns);
    }
  }
}
