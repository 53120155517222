@use "sass:math";

// REFERENCE CODE: https://codepen.io/teroauralinna/pen/JeKrXe?editors=1100
@mixin label-inactive() {
  font-size: $text-18;
  transform: translateY(0);
}

@mixin label-active() {
  color: $black-200;
  font-size: $text-12;
  transform: translateY(-2.6rem);

  &--error {
    color: $red-500;
  }
}

// Tweak these to change the style of the toggle
$toggle-width: 52px;
$toggle-height: 32px;
$toggle-gutter: 3.2px;
$toggle-radius: 50%;
$toggle-control-speed: 0.15s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: math.div($toggle-height, 2);
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.form-field {
  display: block;
  margin-bottom: $spacing-24;
  width: 100%;

  &__label,
  &__display-label {
    @include label-active();
    width: 100%;
    padding-top: $spacing-24;
    margin-bottom: $spacing-4;

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;

    transition: all 0.2s ease-in-out;

    &--white {
      color: $white-500;
    }

    &--white-label {
      color: $wl-black-200;
    }
  }

  &__display-label {
    @include label-inactive();
    position: relative;
    margin-bottom: $spacing-12;

    // Firefox
    -moz-appearance: textfield;

    // Chrome, Safari, Edge, Opera
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__file-label {
    position: relative;
    z-index: 10;
  }

  &__control {
    position: relative;
    width: inherit;

    &--password {
      display: flex;
      align-items: flex-end;
    }

    &--file {
      position: relative;
      width: 100%;
      text-align: center;
      padding: $spacing-32;
      border: 1px dashed $black-100;
      border-radius: $border-radius-lg;

      @media (hover: hover) {
        &:hover {
          border-color: $black-500;
          cursor: pointer;
        }
      }

      &:focus-within {
        outline-offset: 2px;
        outline: 2px solid $black-500;
      }

      &-error {
        border-color: $red-500;
      }

      .file-upload-icon {
        width: 5.6rem;
        height: 5.6rem;
        background-color: $black-025;
        border-radius: $border-radius-full;

        font-size: $text-24;
        color: $black-200;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 auto $spacing-16 auto;
      }

      .file-uploaded-icon {
        @extend .file-upload-icon;
        background-color: $white-500;
        color: $blue-500;
      }
    }

    &--file-dragover {
      @extend .form-field__control--file;

      background-color: $blue-025;
      border-color: $blue-500;

      .file-upload-icon {
        background-color: $blue-500;
        color: $white-500;
      }
    }

    &--file-uploaded {
      @extend .form-field__control--file;

      padding: $spacing-32;
      background-color: $black-025;
      border: none;

      @media (hover: hover) {
        &:hover {
          cursor: default;
        }
      }

      & > .file-upload-close {
        font-size: $text-12;
        width: 2.4rem;
        height: 2.4rem;
        background-color: $white-500;
        border-radius: $border-radius-full;

        position: absolute;
        top: $spacing-8;
        right: $spacing-8;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
      }

      & > img {
        object-fit: contain;
        margin: 0 auto;

        @include respond(xl) {
          width: auto;
          height: 100px;
        }
      }
    }

    &--color {
      display: flex;
      align-items: flex-end;
    }
  }

  &__input,
  &__textarea,
  &__display-input,
  &__display-stepper,
  &__date,
  &__password,
  &__color {
    appearance: none;
    background-color: transparent;
    border: 0;
    outline: 0;

    color: $black-500;
    border-bottom: 1px solid $black-100;
    font-size: $text-18;
    margin-top: $spacing-24;
    padding-bottom: $spacing-12;
    width: 100%;

    &--error,
    &--error ~ .form-field__password__icon {
      border-color: $red-500;
    }

    &::placeholder {
      color: $black-200;
    }

    @media (hover: hover) {
      &:hover,
      &:hover ~ .form-field__password__icon {
        border-bottom: 1px solid $black-500;
      }
    }

    &:-ms-input-placeholder {
      ~ .form-field__label {
        @include label-inactive();
      }
    }

    &:placeholder-shown {
      ~ .form-field__label {
        @include label-inactive();
      }
    }

    &:focus,
    &:focus ~ .form-field__password__icon {
      ~ .form-field__label {
        color: $blue-500;
        @include label-active();
      }

      border-bottom: 1px solid $blue-500;
    }

    &--white-label {
      color: $wl-black-500;
      border-bottom: 1px solid $wl-black-050;

      &:hover {
        border-bottom: 1px solid $wl-black-500;
      }

      &:focus {
        border-bottom: 1px solid $wl-black-500;
      }
    }

    &--character-count {
      font-size: $text-14;
      font-family: $fw-500;
      color: $black-200;

      position: absolute;
      right: 0;
      bottom: $spacing-8;
    }
  }

  &__password {
    &__icon {
      background-color: transparent;

      font-size: $text-18;
      color: $black-200;
      border: 0;
      border-bottom: 1px solid $black-100;
      padding-bottom: $spacing-12;

      z-index: 10;
      cursor: pointer;

      &:focus {
        outline-color: inherit;
      }

      &:hover {
        color: $black-500;
      }
    }
  }

  &__textarea {
    height: 12.4rem;
    resize: none;
  }

  &__file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }

  &__color {
    padding-left: $spacing-8;
    margin-top: 0;
    text-transform: uppercase;

    &__box {
      width: 2.4rem;
      height: 3.6rem;
      border: none;
      background-color: unset;
      border-bottom: 1px solid $black-100;
      padding-bottom: 1.4rem;

      cursor: pointer;

      @include respond(md) {
        padding: 0 0 $spacing-16 0;
        height: 3.8rem;
        width: 2.3rem;
      }

      @include respond(lg) {
        height: 3.8rem;
      }
    }
  }

  &__color__box[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;
  }

  &__color__box[type="color"]::-webkit-color-swatch {
    border: 1px solid $black-050;
    border-radius: $border-radius-full;
  }

  &__display-input,
  &__display-stepper {
    font-size: $text-56;
    font-family: $fw-500;
    border: none;
    margin-top: 0;
    padding-bottom: 0;

    @include respond(md) {
      font-size: $text-80;
    }

    &:hover,
    &:focus {
      border: none;
    }

    &--white {
      color: $white-500;

      &::placeholder {
        color: $blue-300;
        padding-left: $spacing-4;
      }
    }

    &--black {
      color: $black-500;

      &::placeholder {
        color: $black-100;
      }
    }

    &--sm {
      font-size: $text-48;
    }
  }

  &__select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0;
    border: 0;
    padding-bottom: $spacing-12;
    border-bottom: 1px solid $black-100;
    font-size: $text-18;
    color: $black-500;
    background-color: transparent;

    &:hover {
      border-bottom-color: $black-500;
    }

    &:focus-visible {
      outline: none;
      border-bottom-color: $blue-500;
    }

    &__icon {
      display: flex;
    }

    &__content {
      width: inherit;
      margin-left: $spacing-20;
      border-radius: $border-radius-lg;
      background-color: $white-500;
      box-shadow: $box-shadow-md;
    }

    &__item {
      width: inherit;
      font-size: $text-16;
      padding: $spacing-12 $spacing-16;
      margin: $spacing-8;
      border-radius: $border-radius-sm;
      cursor: pointer;

      &:hover {
        background-color: $black-025;
      }

      &:focus-visible {
        outline: 1px solid $black-500;
      }
    }
  }

  &__date[type="date"] {
    position: relative;

    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }

    &:before {
      font-family: "Material Icons Round";
      content: "event";
      text-rendering: optimizeLegibility;
      font-feature-settings: "liga" 1;
      font-size: $text-24;
      color: $black-200;
      position: absolute;
      top: -$spacing-4;
      right: 2px;
    }

    &::placeholder {
      color: $black-200;
    }
  }

  &__date {
    &__icon {
      background-color: transparent;

      font-size: $text-24;
      color: $black-200;
      border: 0;

      z-index: 10;
      cursor: pointer;

      &:focus {
        outline-color: inherit;
      }

      &:hover {
        color: $black-500;
      }
    }
  }


  &__stepper-group {
    display: flex;
    align-items: center;

    width: 28rem;
    margin: 0 auto;

    @include respond(md) {
      width: 32rem;
    }
  }

  & > [class*="radio-group"]:not(:first-child) {
    margin-top: $spacing-12;

    @include respond(md) {
      margin-top: 0;
      margin-left: $spacing-24;
    }
  }

  &__radio-group {
    width: 100%;

    &__radio:not(:checked),
    &__radio:checked {
      position: absolute;
      left: -9999px;
    }

    &__label {
      width: 100%;
      padding: $spacing-24;
      display: flex;
      align-items: flex-start;

      background-color: transparent;
      border: 1px $black-050 solid;
      border-radius: $border-radius-lg;

      cursor: pointer;

      &:focus-visible {
        outline-color: $pink-400;
      }

      @include respond(md) {
        padding: $spacing-32;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    &__radio:checked + &__label,
    &__label[data-state="on"] {
      background-color: $pink-025;
      border-color: $pink-400;
      transition: all 0.2s ease-in-out;
    }

    &__icon {
      color: $pink-500;
      background-color: $pink-025;
      font-size: $text-24;
      padding: $spacing-16;
      border-radius: $border-radius-full;
    }

    &__radio:checked + &__label > &__icon,
    &__label[data-state="on"] > &__icon {
      color: $white-500;
      background-color: $pink-500;
      transition: all 0.2s ease-in-out;
    }

    &__content {
      text-align: left;
      margin-left: $spacing-16;

      @include respond(md) {
        text-align: center;
        margin-left: $spacing-0;
        margin-top: $spacing-24;
      }

      span {
        font-family: $fw-500;
      }

      p {
        margin-top: $spacing-4;
        color: $black-300;
        font-size: $text-14;

        @include respond(md) {
          margin-top: $spacing-8;
        }
      }
    }

    &__radio:checked + &__label > &__content,
    &__label[data-state="on"] > &__content {
      span {
        color: $pink-500;
      }

      p {
        color: $pink-300;
      }

      transition: all 0.2s ease-in-out;
    }
  }

  &__toggle-group {
    &__toggle-switch:not(:checked),
    &__toggle-switch:checked {
      position: absolute;
      left: -9999px;
    }

    &__toggle-switch {
      display: block;
      padding-left: $toggle-width;
      margin-bottom: 12px;
      font-size: 22px;
      outline: $blue-500 solid 2px;
    }

    &__toggle-switch:checked ~ &__control,
    &__control[data-state="checked"] {
      background-color: $blue-500;

      &::after {
        left: $toggle-width - $toggle-control-size - $toggle-gutter;
      }
    }

    &__control {
      all: unset;
      position: absolute;
      top: 0;
      right: 0;
      height: $toggle-height;
      width: $toggle-width;
      border-radius: $toggle-radius;
      background-color: $black-050;
      transition: background-color $toggle-control-speed $toggle-control-ease;

      cursor: pointer;

      &:focus {
        outline: 2px solid $black-500;
      }

      &::after {
        content: "";
        position: absolute;
        left: $toggle-gutter;
        top: $toggle-gutter;
        width: $toggle-control-size;
        height: $toggle-control-size;
        border-radius: $toggle-radius;
        background: $white-500;
        transition: left $toggle-control-speed $toggle-control-ease;
      }
    }
  }

  &__checkbox-group {
    display: flex;
    align-items: center;

    &__checkbox,
    &__label {
      cursor: pointer;
    }

    &__checkbox:not(:checked),
    &__checkbox:checked {
      position: absolute;
      left: -9999px;
    }

    &__checkbox:not(:checked) + &__label,
    &__checkbox:checked + &__label {
      position: relative;
      padding-left: 3rem;
    }

    &__checkbox:not(:checked) + &__label::before,
    &__checkbox:checked + &__label::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2.2rem;
      height: 2.2rem;
      border: 1px solid $black-100;
      border-radius: $border-radius-xs;
      background: transparent;
    }

    &__checkbox:not(:checked) + &__label::after,
    &__checkbox:checked + &__label::after {
      font-family: "Material Icons Round";
      content: "done";
      text-rendering: optimizeLegibility;
      font-feature-settings: "liga" 1;
      line-height: 2.2rem;
      font-size: $text-16;
      width: 2.2rem;
      height: 2.2rem;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      overflow: hidden;
      color: $white-500;
      background-color: $blue-500;
      border-radius: $border-radius-xs;
      transition: all 0.2s;
    }

    &__checkbox:not(:checked):focus-visible + &__label::before {
      box-shadow: 0px 0px 0px 2px $black-050;
    }

    &__checkbox:not(:checked) + &__label::after {
      opacity: 0;
    }

    &__checkbox:checked + &__label::after {
      opacity: 1;
    }

    &__label {
      font-size: $text-14;
    }

    &__checkbox[disabled] + &__label {
      color: $black-100;
      cursor: not-allowed;
    }

    &__checkbox[disabled] + &__label::before {
      border: 1px solid $black-050;
    }
  }

  &__radio-button-group {
    display: inline-flex;
    align-items: center;

    &__radio,
    &__label {
      cursor: pointer;
    }

    &__radio:not(:checked),
    &__radio:checked {
      position: absolute;
      left: -9999px;
    }

    &__radio:not(:checked) + &__label,
    &__radio:checked + &__label {
      position: relative;
      padding-left: 3rem;
    }

    &__radio:not(:checked) + &__label::before,
    &__radio:checked + &__label::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 2.2rem;
      height: 2.2rem;
      border: 1px solid $black-100;
      border-radius: $border-radius-full;
      background: transparent;
    }

    &__radio:not(:checked) + &__label::after,
    &__radio:checked + &__label::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 2.2rem;
      height: 2.2rem;
      border: 6px solid $blue-500;
      border-radius: $border-radius-full;
      transition: all 0.2s;
    }

    &__radio-box {
      margin-right: $spacing-8;
      width: 2.2rem;
      height: 2.2rem;
      border: 1px solid $black-100;
      border-radius: $border-radius-full;
      background: transparent;
      transition: all 0.2s;

      &:focus {
        outline-color: $black-500;
        outline-offset: 4px;
      }

      &[data-state="checked"] {
        border: 5px solid $blue-500;
      }
    }

    &__radio:not(:checked) + &__label::after {
      opacity: 0;
    }

    &__radio:checked + &__label::after {
      opacity: 1;
    }

    &__label {
      font-size: $text-16;
    }

    &__radio[disabled] + &__label {
      color: $black-100;
      cursor: not-allowed;
    }

    &__radio[disabled] + &__label::before {
      border: 1px solid $black-050;
    }
  }
}

// STYLES USED FOR THE PHONE INPUT
// EXTERNAL – CAN'T REALLY CONTROL CHANGING THE CLASS NAMES IN HERE
#phoneLabel {
  padding-top: 0;
}
.intl-tel-input {
  display: block !important;
  margin: 6.4rem 0 0 0;
}
.react-tel-input .form-control {
  border: 0 !important;
  border-bottom: 1px solid $black-100 !important;
  border-radius: unset !important;
  letter-spacing: unset !important;
  line-height: 1.5 !important;
  font-size: $text-18 !important;
  width: 100% !important;
  padding-bottom: $spacing-12;
  padding-left: 5.2rem !important;
  height: unset !important;
  font-family: $fw-400;

  &:hover,
  &:hover + .flag-dropdown {
    border-color: $black-500 !important;
  }

  &:focus,
  &:focus + .flag-dropdown {
    border-color: $blue-500 !important;
  }

  &::placeholder {
    color: $black-200 !important;
  }

  &--premium {
    &:focus,
    &:focus + .flag-dropdown {
      border-color: $black-500 !important;
    }
  }
}
.react-tel-input .selected-flag {
  padding: 0 !important;
  margin-top: -0.5rem !important;
}
.react-tel-input .country-list {
  position: unset !important;
  margin-top: $spacing-16 !important;
}
.react-tel-input .country-list .country:hover {
  background-color: $black-025 !important;
  border-radius: $border-radius-sm !important;
}
.react-tel-input .country-list .divider {
  border-bottom: 1px solid $black-050 !important;
  padding-bottom: $spacing-8 !important;
  margin-bottom: $spacing-8 !important;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid $black-100 !important;
  border-radius: unset !important;
}
.intl-tel-input.allow-dropdown .selected-flag,
.flag-dropdown .selected-flag {
  width: 4.2rem !important;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag,
.flag-dropdown:hover .selected-flag {
  background-color: transparent !important;
}
.intl-tel-input .flag-container {
  padding: 0 !important;
  top: -10px !important;
}
.intl-tel-input .flag-container .arrow {
  margin-left: $spacing-4 !important;
}
.react-tel-input .country-list .flag {
  position: unset !important;
  left: 0 !important;
  top: 0 !important;
}
.intl-tel-input .country-list,
.country-list {
  max-height: 25.6rem !important;
  margin: $spacing-8 0 0 0 !important;
  padding: $spacing-8 !important;
  box-shadow: $box-shadow-md !important;
  border: 0 !important;
  border-radius: $border-radius-lg !important;

  font-size: $text-16 !important;
  font-family: $fw-400 !important;
}
.country-list {
  width: unset !important;
  min-width: 100% !important;
}
.intl-tel-input .country-list .country.highlight,
.country-list .country.highlight {
  transition: all 0.1s ease-in-out !important;
  background-color: $black-025 !important;
  border-radius: $border-radius-sm !important;
}
.intl-tel-input .country-list .country {
  padding: $spacing-12 $spacing-24 !important;
  display: flex !important;
  align-items: center !important;
}
.country-list .country {
  padding: $spacing-12 $spacing-24 !important;
  display: flex !important;
}
.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name,
.country .country-name {
  margin-right: $spacing-12 !important;
}
.intl-tel-input .country-list .country .dial-code {
  color: $black-200 !important;
  width: 100% !important;
  text-align: right !important;
}
.country .dial-code {
  color: $black-200 !important;
  margin-left: auto;
}
.iti-flag,
.flag {
  box-shadow: 0 !important;
  background-color: 0 !important;
}
.flag {
  margin-right: $spacing-8 !important;
}
// .arrow,
.down {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.intl-tel-input.allow-dropdown .arrow {
  display: none;
}
.intl-tel-input.allow-dropdown .selected-flag:after {
  content: "\e5cf";
  font-family: "Material Icons Round";
  font-size: $text-16;
  margin-left: $spacing-4;
}
.intl-tel-input.allow-dropdown.expanded .selected-flag:after {
  color: $blue-500;
  content: "\e5ce";
}

// HTML VERSION OF THE TEL INPUT
.iti {
  margin: $spacing-24 0 0 0;
  width: inherit;
}
.iti__flag-container {
  top: -10px !important;
}
.iti__selected-flag {
  width: 4.2rem !important;
  padding: 0 !important;
}
.iti__country-list {
  max-height: 25.6rem !important;
  margin: $spacing-8 0 0 0 !important;
  padding: $spacing-8 !important;
  box-shadow: $box-shadow-md !important;
  border: 0 !important;
  border-radius: $border-radius-lg;

  font-size: $text-16;
  font-family: $fw-400;
}
.iti__country.iti__active.iti__highlight,
.iti__country.iti__highlight {
  transition: all 0.1s ease-in-out;
  background-color: $black-025 !important;
  border-radius: $border-radius-sm;
}
.iti__country-list .iti__country {
  padding: $spacing-12 $spacing-24 !important;
  display: flex;
  align-items: center;
}
.iti__country .iti__flag-box,
.iti__country .iti__country-name {
  margin-right: $spacing-12 !important;
}
.iti__country .iti__dial-code {
  color: $black-200 !important;
  width: 100%;
  text-align: right;
}

// STYLES USED FOR THE AMOUNT DISPLAY INPUT FIELD
input.display-amount {
  appearance: none;
  background: transparent;
  outline: 0;
  border: none;

  text-align: center;
  width: 100%;

  font-size: $text-56;
  font-family: $fw-500;
  margin-top: $spacing-24;
  padding-bottom: 0;
  color: $white-500;

  @include respond(md) {
    font-size: $text-80;
  }

  &::placeholder {
    color: $blue-300;
    padding-left: $spacing-4;
  }
}
input.amount-display-wl {
  @extend .display-amount;

  &--white {
    &::placeholder {
      color: $wl-white-200;
    }
  }

  &--black {
    &::placeholder {
      color: $wl-black-200;
    }
  }
}
