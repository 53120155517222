.pay {
  background-image: url("../../assets/misc/gradient-bg-mobile.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  padding: $spacing-24 0;
  
  @include respond(md) {
    background-image: url("../../assets/misc/gradient-bg.png");
  }
  
  &__wrapper {
    @include global-grid();
  }

  &__header {
    grid-column: 1 / -1;
    justify-self: center;
  }

  &__success {
    grid-column: 1 / -1;

    @include respond(md) {
      grid-column: 3 / span 5;
    }

    @include respond(lg) {
      grid-column: 5 / span 4;
    }
  }

  &__card {
    @include card();
    border-radius: $border-radius-xl;
    min-height: 47.5rem;

    margin-top: $spacing-24;
    grid-column: 1 / -1;

    @include respond(md) {
      grid-column: 3 / span 5;
    }

    @include respond(lg) {
      grid-column: 5 / span 4;
    }
  }
}

