// TEXT SIZES
@each $text-name, $size in $text-sizes {
  .#{$text-name} {
    font-size: $size;
  }
  .hover\:#{$text-name} {
    &:hover {
      font-size: $size;
    }
  }
}

// TEXT ALIGNMENTS
@each $align in $text-alignments {
  .text-#{$align} {
    text-align: $align;
  }
}

// TEXT TRANSFORMS
@each $transform in $text-transforms {
  .#{$transform} {
    text-transform: $transform;
  }
}

// TEXT DECORATION
@each $name, $value in $text-decorations {
  .#{$name} {
    text-decoration: $value;
  }
  .hover\:#{$name} {
    &:hover {
      text-decoration: $value;
    }
  }
}

// FONT WEIGHTS
@each $weight-name, $weight in $font-weights {
  .font-#{$weight-name} {
    font-family: $weight;
  }
  .hover\:font-#{$weight-name} {
    &:hover {
      font-family: $weight;
    }
  }
}

// RESPONSIVE: TEXT SIZES
@each $text-name, $size in $text-sizes {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:#{$text-name} {
        font-size: $size;
      }

      .#{$bp-name}\:hover\:#{$text-name} {
        &:hover {
          font-size: $size;
        }
      }
    }
  }
}

// RESPONSIVE: TEXT ALIGNMENTS
@each $align in $text-alignments {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:text-#{$align} {
        text-align: $align;
      }
    }
  }
}


// RESPONSIVE: FONT WEIGHTS
@each $weight-name, $weight in $font-weights {
  @each $bp-name, $bp-size in $breakpoints {
    @include respond($bp-name) {
      .#{$bp-name}\:font-#{$weight-name} {
        font-family: $weight;
      }
    }
  }
}
