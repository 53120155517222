.modal {
  height: 100vh;
  padding: $spacing-20;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  &--full {
    padding: 0;
  }

  &__box {
    width: 100%;
    max-width: 52.8rem;
    max-height: 61.4rem;
    overflow-y: scroll;
    background-color: $white-500;
    padding: $spacing-32;
    border-radius: $border-radius-lg;
    z-index: 1001;

    position: relative;
    animation: slide-up 0.5s cubic-bezier(0.16, 1, 0.3, 1);

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @include respond(md) {
      max-width: 54.4rem;
      padding: $spacing-48;
    }

    &--full {
      width: 100%;
      max-width: none;
      max-height: unset;
      height: 100%;
      padding: $spacing-20;
      border-radius: 0;

      @include respond(lg) {
        min-width: min-content;
        max-width: 54.4rem;
        max-height: 61.4rem;
        height: auto;
        padding: $spacing-48;
        border-radius: $border-radius-lg;
      }

      & > .modal__box__close,
      & > .modal__box__back {
        font-size: $text-24;

        @include respond(lg) {
          font-size: $text-18;
        }
      }

      & > .modal__box__content {
        margin-top: $spacing-64;

        @include respond(lg) {
          margin-top: 0;
        }
      }
    }

    &__close,
    &__back,
    &__share {
      padding: $spacing-4;
      color: $black-200;
      font-size: $text-18;
      position: absolute;
      top: $spacing-16;

      cursor: pointer;

      background-color: transparent;
      border: 0;
      border-radius: $border-radius-full;

      &:focus {
        outline: none;
        outline-color: inherit;
      }

      @include respond(md) {
        top: $spacing-24;
      }
    }

    &__close {
      right: $spacing-16;

      @include respond(md) {
        right: $spacing-24;
      }
    }

    &__back,
    &__share {
      left: $spacing-16;

      @include respond(md) {
        left: $spacing-24;
      }
    }

    &__content {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__icon {
        padding: $spacing-12;
        font-size: $text-24;
        border-radius: $border-radius-full;

        @include respond(md) {
          margin-bottom: $spacing-32;
        }

        &--warning {
          color: $yellow-500;
          background-color: $yellow-050;
        }
      }

      &__title {
        margin-top: $spacing-24;

        @include respond(md) {
          margin-top: 0;
        }
      }

      &__text {
        margin-top: $spacing-8;
        margin-bottom: $spacing-24;
        color: $black-300;
        font-size: $text-16;

        @include respond(md) {
          margin-bottom: $spacing-32;
          font-size: $text-18;
        }
      }
    }
  }

  &__overlay {
    background-color: rgba($black-500, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
}
